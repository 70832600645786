<template>
    <div>
        <!--begin::User-->
        <div class="card card-custom">
            <div class="card-header flex-wrap border-0 pt-6 pb-0">
                <div class="card-title">
                    <!-- <h3 class="card-label">{{ $t('MENU.payments_management') }}
                        <span class="text-muted pt-2 font-size-sm d-block">{{ $t('MENU.payments_details') }}</span>
                    </h3> -->
                </div>
                <div class="card-toolbar">
                    <!--begin::Button-->
                    <router-link to="/purchases/payment_purchase_invoices" class="btn btn-light font-weight-bolder">
                        <v-icon>mdi-chevron-left</v-icon>
                        {{ $t('back') }}
                    </router-link>
                    <!--end::Button-->
                </div>
            </div>
            <div class="card-body">

                <div class="mb-5 d-flex justify-content-end">
                    <b-button-group>
                        <b-button target="_blank" :href="`/print/purchases/payment/${idEdit}/print`">{{ $t('payment_purchase_invoices_details.receipt') }}</b-button>
                        <b-button target="_blank" :href="`/print/purchases/payment/${idEdit}/pdf`">{{ $t('export_pdf') }}</b-button>
                        <b-button @click="editItem(idEdit)">{{ $t('payment_purchase_invoices_details.edit') }}</b-button>
                        <b-button variant="danger" @click="deleteItem(idEdit)">{{ $t('payment_purchase_invoices_details.delete') }}</b-button>
                    </b-button-group>
                </div>

                <b-tabs content-class="mt-3">
                    <b-tab :title="$t('basic_information')">
                        <br>
                        <table v-if="data" class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>{{ $t('payment_purchase_invoices_details.Supplier_Info') }}</th>
                                    <th>
                                        <span>{{ $t('payment_purchase_invoices_details.Payment_Details') }}</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <span>{{ $t('payment_purchase_invoices_details.Supplier_Name') }}</span>
                                        <span class="float-right">{{ data.supplier_fullname }}</span>
                                    </td>
                                    <td>
                                        <span>{{ $t('payment_purchase_invoices_details.Invoice_No') }}</span>
                                        <span class="float-right">{{ data.invoice ? data.invoice.invoice_code : '' }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>{{ $t('payment_purchase_invoices_details.Street_Address') }}</span>
                                        <span class="float-right">{{ data.supplier?data.supplier.address_1:'' }}</span>
                                    </td>
                                    <td>
                                        <span>{{ $t('payment_purchase_invoices_details.Payment_Method') }}</span>
                                        <span class="float-right">{{ data.payment_method_name }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>{{ $t('payment_purchase_invoices_details.City') }}</span>
                                        <span class="float-right">{{(data.supplier && data.supplier.city)?data.supplier.city.name:'' }}</span>
                                    </td>
                                    <td colspan="2">
                                        <span>{{ $t('payment_purchase_invoices_details.Amount') }}</span>
                                        <span class="float-right">{{ data.amount }} {{ data.currency_code }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>{{ $t('payment_purchase_invoices_details.State') }}</span>
                                        <span class="float-right">{{ data.supplier?data.supplier.state:'' }}</span>
                                    </td>
                                    <td>
                                        <span>{{ $t('payment_purchase_invoices_details.Transaction_ID') }}</span>
                                        <span class="float-right">{{ data.ref_no}}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>

                                        <span>{{ $t('payment_purchase_invoices_details.Postal_Code') }}</span>
                                        <span class="float-right">{{ data.supplier?data.supplier.postal_code:'' }}</span>
                                    </td>
                                    <td>
                                        <span>{{ $t('payment_purchase_invoices_details.Status') }}</span>
                                        <span class="float-right">{{ data.payment_status_name }}</span>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <span>{{ $t('payment_purchase_invoices_details.Telephone') }}</span>
                                        <span class="float-right">{{ data.supplier?data.supplier.phone:'' }}</span>
                                    </td>
                                    <td>
                                        <span>{{ $t('payment_purchase_invoices_details.Date') }}</span>
                                        <span class="float-right">{{ data.payment_date}}</span>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <span>{{ $t('payment_purchase_invoices_details.Country') }}</span>
                                        <span class="float-right">{{(data.supplier && data.supplier.country)?data.supplier.country.name:'' }}</span>
                                    </td>
                                    <td>
                                        <span>{{ $t('payment_purchase_invoices_details.Added_By') }}</span>
                                        <span class="float-right">{{ data.added_user_name }}</span>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <span>{{ $t('payment_purchase_invoices_details.Currency') }}</span>
                                        <span class="float-right">{{ data.currency_code }}</span>
                                    </td>
                                    <td>
                                        <span>{{ $t('payment_purchase_invoices_details.Details') }}</span>
                                        <span class="float-right">{{ data.payment_details }}</span>
                                    </td>
                                </tr>

                                <tr>
    <!--                                <td>-->
    <!--                                    <span>{{ $t('payment_purchase_invoices_details.IP') }}</span>-->
    <!--                                    <span class="float-right">IP</span>-->
    <!--                                </td>-->
                                    <td>
                                        <span>{{ $t('payment_purchase_invoices_details.Receipt_Notes') }}</span>
                                        <span class="float-right">{{ data.payment_details }}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p v-else class="text-center">{{ $t('no_data_to_show')}}</p>
                    </b-tab>
                <b-tab v-if="idEdit" :title="$t('activity_log.activity_log')">
                    <br>
                    <activity-log :inner-key="key" :id="idEdit"></activity-log>
                </b-tab>
                </b-tabs>
                <!--end: Datatable-->
            </div>
        </div>
        <!--end::User-->

    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import ActivityLog from "@/view/content/widgets/details/ActivityLog";

    export default {
        name: "details-payment_sales_invoices",
        components: {'activity-log': ActivityLog},
        data() {
            return {
                mainRoute:'sales/payment_sales_invoices',
                idEdit: this.$route.params.id?this.$route.params.id:null,
                data : [],
                key: 'PaymentSales',
                url_type: null,
            }
        },
        computed: {
            
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.payment_purchase_invoices_details")}]);
            this.getData();
        },
        methods: {
           
            async getData() {
                await ApiService.get(`${this.mainRoute}/${this.idEdit}`).then((response) => {
                    this.data = response.data.data;
                    this.url_type = response.data.data.url_type;
                });
            },

            deleteItem(id) {
                this.$confirmAlert('', this.actionDelete, id);
            },
            actionDelete(id) {
                ApiService.delete(`${this.mainRoute}/${id}`).then((response) => {
                    this.$successAlert(response.data.message);
                    this.$router.push({name: 'payment_sales_invoices.index'});
                }).catch((error) => {
                    this.$errorAlert(error);
                })
            },
            editItem(id){
                this.$router.push({name: 'payment_purchase_invoices.edit', params:{id: id, type: this.url_type}});
            }
        },
    };
</script>
